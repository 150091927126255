
import { AuthModule } from '@/store/modules';
import { Component, Vue, Emit } from 'vue-property-decorator';

@Component
export default class OffersTab extends Vue {
  activetab = 'offers';

  created(): void {
    if (this.$route.query.myoffers) {
      this.activetab = 'myoffers';
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  @Emit('switchtab')
  switchTab(tab: string): string {
    this.activetab = tab;
    return tab;
  }

  get isAuthenticated(): boolean {
    return AuthModule.isAuthenticated;
  }
}
